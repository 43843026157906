<script>
import listingBox from "@/components/listing-box.vue";
import { wpService } from "@/services/wp";
export default {
  components: {
    listingBox,
  },
  props: {
    entry: {
      default: null,
      type: Object,
    },
  },
  data() {
    return {
      editing: false,
      ltg_desc: null,
    };
  },
  created() {
    this.reset();
  },
  computed: {
    canSubmit() {
      if (this.ltg_desc !== this.entry.ltg_desc)
        return { ltg_desc: this.ltg_desc };
      return false;
    },
  },
  methods: {
    reset() {
      this.ltg_desc = this.entry.ltg_desc;
    },
    submit() {
      let loading = this.$buefy.loading.open({ container: null });
      wpService.listings
        .patchSingle(this.entry.ltg_id, this.canSubmit)
        .then(() => {
          this.editing = false;
          this.$buefy.toast.open({
            message: "Descripción actualizada!",
            type: "is-success",
          });
          loading.close();
        })
        .catch(err => {
          console.log(err);
          loading.close();
        });
    },
  },
  watch: {
    editing() {
      this.reset();
    },
  },
};
</script>

<template>
  <listingBox
    title="Descripción"
    editable
    :editing="editing"
    @editing="editing = $event"
  >
    <div v-if="editing">
      <textarea class="textarea" rows="15" v-model="ltg_desc"></textarea>
      <div class="submit">
        <b-button type="is-primary" :disabled="!canSubmit" @click="submit"
          >Guardar</b-button
        >
      </div>
    </div>
    <div
      v-else-if="entry.ltg_desc.length"
      v-html="entry.ltg_desc.replace(/\r?\n/g, '<br />')"
    ></div>
    <div v-else>
      <b-message type="is-warning">
        <b-icon icon="eye" />
        <span><b>Recomendado: </b>Redactar una descripción.</span>
      </b-message>
    </div>
  </listingBox>
</template>
